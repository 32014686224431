@import "../base/mixin";
@import "../base/variables";

.color-primary {
    color: $color-primary !important;
}
.color-blue {
    color: $color-blue !important;
}

.filter-wrapper {
    margin-bottom: 15px;

    .ant-card-body {
        padding: 12px !important;

        display: flex;

        @include tablet {
            flex-direction: column;
        }

        & > div {
            margin-left: 12px;

            @include tablet {
                margin-left: 0;
                margin-top: 12px;
            }

            &:first-child {
                margin-left: 0;

                @include tablet {
                    margin-top: 0;
                }
            }
        }

        .search-box {
            flex-grow: 1;
        }

        .select-box {
            .ant-select {
                width: 150px;

                margin-right: 12px;

                @include tablet {
                    width: 100%;

                    margin-right: 0;
                    margin-bottom: 12px;
                }

                &:last-child {
                    margin-right: 0;

                    @include tablet {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .date-box {
            flex-shrink: 0;

            @include mobile {
                display: flex;
                flex-direction: column;
            }

            .ant-picker {
                margin-right: 12px;

                @include mobile {
                    width: 100%;
                    
                    margin-right: 0;
                    margin-bottom: 12px;
                }

                &:last-child {
                    margin-right: 0;

                    @include mobile {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.table-box {
    overflow-x: auto;
}
